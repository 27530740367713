<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3"> 
        <c-card title="방사선작업 상세정보" class="cardClassDetailForm" height="415px">
          <template slot="card-detail">
            <div class="col-12">
              <c-field 
                :disabled="!isWriting"
                :editable="editable"
                :data="supWork"
                deptValue="radiationResponsibleDeptCd"
                type="dept_user" 
                label="방사능 책임자" 
                beforeText=""
                name="radiationResponsibleUserId" 
                v-model="supWork.radiationResponsibleUserId"
                @dataChange="dataChange" />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
        <c-table
          title="안전조치 요구사항"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="supWork.checkResults"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&isWriting"
          :contentsField="contentsField"
          @table-data-change="tableDataChange"
        >
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'swp-radiation',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          swpStepCd: '',  // 진행단계 코드
        }
      }
    },
    supWork: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          supplementWorkTypeCd: '',  // 보충작업구분
          workProposalNo: '',  // 작업 신청번호_방사선, 고소, 중장비
          workLocation: '',  // 작업장소_방사선, 고소, 중장비
          workSummary: '',  // 작업개요_고소, 방사선,굴착, 중장비
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          relationCooperation1DeptCd: '',  // 관려부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관려부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          specialRequirements: '',  // 특별요구사항
          closeMachine: '',  // (밀폐)장치명
          closeEntryResaon: '',  // (밀폐)출입사유
          closeEntryUserIds: '',  // (밀폐)출입자id_구분자이용 복수등록
          closeEntryUserNames: '',  // (밀폐)출입자명_구분자이용 복수등록
          closeExpectedRisk: '',  // (밀폐)예상위험
          blackoutCircuitBreaker: '',  // (정전)차단기기
          blackoutCircuitBreakerNo: '',  // (정전)차단기 번호
          blackoutPowerRecoveryRequestUserId: '',  // (정전)전원복구 요청자
          blackoutPowerRecoveryTime: '',  // (정전)전원복구 시간
          blackoutElectricBlockUserId: '',  // (정전)차단확인자(전기)
          blackoutMaintenanceBlockUserId: '',  // (정전)차단확인자(정비)
          radiationResponsibleUserId: '',  // (방사선)방사능 책임자
          heavyEquipSafetyCheckUserId: '',  // (중장비)안전조치 확인자
          heavyEquipManageUserId: '',  // (중장비)장비관리 책임자
          heavyEquipDriveUserId: '',  // (중장비)운전원
          heavyEquipDirectorRequirements: '',  // (중장비) 감독 요구사항
          heavyEquipRequireDeptRequirements: '',  // (중장비)요청부서 요구사항
          heavyEquipSupportDeptRequirements: '',  // (중장비) 지원부서 요구사항
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          checkResults: [], // 안전조치항목
          gases: [], // 가스항목측정 ** 사용하지 않는 보충작업도 존재하지만 빈 배열을 할당시켜 관리
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          editFlag: 'C',
          entrants: [],
        }
      }
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '450px'
      },
      editable: true,
      listCheckItemUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    'workPermit.plantCd'() {
      this.$set(this.supWork, 'checkResults', [])
      this.getCheckItemList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listCheckItemUrl = selectConfig.sop.swp.workChekItem.list.url
      // code setting
      // list setting
      this.getCheckItemList();
    },
    getCheckItemList() {
      /**
       * default 값을 조회
       * 
       * 초기인 경우 workPermit.checkResults에 값을 그대로 적용
       * 작성중 단계인 경우 default 값에 새로운 항목이 있을시에 workPermit.checkResults에 추가하여 입력을 받을 수 있도록 조치
       * 승인완료, 연장, 취소, 작업완료인 경우 저장된 데이터를 그대로 표시하며 이 경우 default값을 조회하지 않도록 조치
       */
      this.grid.columns = [
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '항목',
          align: 'left',
          sortable: true,
        },
        {
          name: 'necessaryFlag',
          field: 'necessaryFlag',
          label: '필요',
          align: 'center',
          sortable: false,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
        },
        {
          name: 'confirmFlag',
          field: 'confirmFlag',
          label: '확인',
          align: 'center',
          sortable: false,
          type: 'check',
          disableTarget: 'necessaryFlag',
          disableCon: 'Y',
          'true': 'Y',
          'false': 'N',
          value: 'N',
          setHeader: true,
        },
      ]
      if (!this.workPermit.plantCd) return;
      if (!this.supWork.checkResults) this.supWork.checkResults = [];
      if (!this.isOld || this.workPermit.swpStepCd === 'SS00000001') {
        this.$http.url = this.listCheckItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.workPermit.plantCd,
          sopWorkTypeCd: 'SWT0000020',
          useFlag: 'Y',
        }
        this.$http.request((_result) => {
          if (!this.isOld && (!this.supWork.checkResults || this.supWork.checkResults.length === 0)) {
            // 최초인 경우 조치항목을 가지고 있지 않음으로 해당 값을 표시
            this.$_.forEach(_result.data, item => {
              this.supWork.checkResults.push({
                sopWorkPermitId: '',  // 작업허가서 일련번호
                supplementWorkTypeCd: this.supWork.supplementWorkTypeCd,  // 보충작업구분
                sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                checkItemName: item.checkItemName, // 점검항목명
                sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                necessaryFlag: 'N',  // 필요
                confirmFlag: 'N',  // 확인
                confirmUserId: '',  // 확인자
                confirmUserName: '',  // 확인자명
                regUserId: this.$store.getters.user.userId,  // 등록자 ID
                chgUserId: '',  // 수정자 ID
                editFlag: 'C'
              })
            })
          } else {
            this.$_.forEach(_result.data, item => {
              if (this.$_.findIndex(this.supWork.checkResults, { sopCheckItemId: item.sopCheckItemId }) === -1) {
                this.supWork.checkResults.push({
                  sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
                  supplementWorkTypeCd: this.supWork.supplementWorkTypeCd,  // 보충작업구분
                  sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
                  checkItemName: item.checkItemName, // 점검항목명
                  sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
                  necessaryFlag: 'N',  // 필요
                  confirmFlag: 'N',  // 확인
                  confirmUserId: '',  // 확인자
                  confirmUserName: '',  // 확인자명
                  regUserId: this.$store.getters.user.userId,  // 등록자 ID
                  chgUserId: '',  // 수정자 ID
                  editFlag: 'C'
                })
              }
            })
          }
          if (this.$_.findIndex(_result.data, { sopCheckItemKindCd: 'SCIK000005' }) > -1 
            || this.$_.findIndex(this.supWork.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
            this.grid.columns.push({
              name: 'confirmUserName',
              field: 'confirmUserName',
              label: '확인자',
              align: 'center',
              sortable: true,
              type: 'user',
              userId: 'confirmUserId',
              disableTarget: 'necessaryFlag',
              disableCon: 'Y',
            })
          }
        },);
      } else {
        if (this.$_.findIndex(this.workPermit.checkResults, { sopCheckItemKindCd: 'SCIK000005' }) > -1) {
          this.grid.columns.push({
            name: 'confirmUserName',
            field: 'confirmUserName',
            label: '확인자',
            align: 'center',
            sortable: true,
            type: 'user',
            userId: 'confirmUserId',
            disableTarget: 'necessaryFlag',
            disableCon: 'Y',
          })
        }
      }
    },
    dataChange() {
      if (this.supWork.editFlag !== 'C') {
        this.supWork.editFlag = 'U'
        this.supWork.chgUserId = this.$store.getters.user.userId
      }
    },
    contentsField(props, col) {
      /**
       * 안전조치사항 항목에서 항목 입력 타입이 '테이블_점검자' 인 경우 테이블에 표시
       * 단, 예전에 '테이블_점검자'였으며 그럼으로 인해 데이터가 저장이 된적이 있는 경우 컴포넌트 표시
       */
      let returnVal = true;
      if (col.name === 'confirmUserName') {
        returnVal = props.row['sopCheckItemKindCd'] === 'SCIK000005' || Boolean(props.row['confirmUserName'])
      }
      return returnVal
    },
    tableDataChange(props, col) {
      if (col.name === 'necessaryFlag' && props.row[col.name] !== 'Y') {
        this.$set(props.row, 'confirmFlag', 'N');
      }
    },
  }
};
</script>
